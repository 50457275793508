import React, { useEffect, useState } from 'react'
import * as styles from './components.module.less'
import Layout from '../../layouts/default'
import { StaticImage } from 'gatsby-plugin-image'
import Headline from '../../components/headline'
import t from '../../lib/translate'
import SectionContainer from '../../components/section-container'
import { graphql, Link, StaticQuery } from 'gatsby'
import Search from '../../lib/services/search';
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import SiteSearchBar from '../../components/site-search-bar'

export default function Suchergebnis() {
    const [ searchString ] = useQueryParam('s', withDefault(StringParam), 's')
    
    const [isSearching, setisSearching] = useState(true)
    const [response, setResponse] = useState({})
    
    useEffect((e) => {
        const SearchService = new Search(process.env.GATSBY_API_SEARCH_URL)

        if (searchString) {
            SearchService.find(searchString).then(result => {
                
                const searchResult = result
                searchResult.data = result.data
                .filter(enty => enty.attributes.score >= 1)
                .map(entry => {
                    const newEntry = entry
                    newEntry.attributes.url = newEntry.attributes.url
                        .replace(/^(https?:\/\/)?[^/]+(.*)$/g, ($0, $1, $2) => $2)
                    return newEntry
                })
                
                console.log(searchResult)
                setResponse(searchResult)

                setisSearching(false)
            })
        }

    }, [searchString])

    return (
        <StaticQuery
            query={query}
            render={data => (
                <Layout config={data.allDatoCmsConfig.nodes[0]} noFooter>
                    <div className={styles.headerContainer}>
                        <Headline
                            className={styles.headerHeadline}
                            semanticOfHeadline='H1'
                        >
                            {t('page.search-result.headline', [searchString ?? ''])}
                        </Headline>
                        <StaticImage
                            className={styles.headerBackgroundImage}
                            src='../../static/assets/Images/header-unterseite-bg-2x.webp'
                            alt=''
                        />
                    </div>

                    <SectionContainer sectionClass={styles.siteSearchBar}>
                        <SiteSearchBar />
                    </SectionContainer>

                    <SectionContainer sectionClass={styles.searchResults}>
                        {!searchString && isSearching &&
                            <p>{t`page.search-result.message.no-search-string`}</p>
                        }

                        {searchString && isSearching &&
                            <p>{t`page.search-result.message.loading`}</p>
                        }

                        {response?.data?.length === 0 &&
                            <p>{t('page.search-result.message.no-results', [searchString ?? ''])}</p>
                        }

                        {(!isSearching && response?.data?.length > 0) &&
                            response?.data?.map(searchResult => (
                                <Link key={searchResult.id} className={styles.result} to={searchResult.attributes.url}>
                                    <div className={styles.textWrap}>
                                        <span>{searchResult.attributes.title}</span>
                                        <p>{`${searchResult.attributes.body_excerpt} ...`}</p>
                                    </div>
                                </Link>
                            ))
                        }
                    </SectionContainer>
                </Layout>
            )}
        />
    )
}

const query = graphql`
    query configQuery {
        allDatoCmsConfig {
            nodes {
                id
                locale
                pageContact {
                    id
                    name
                    slug
                }
                pageDisruption {
                    id
                    name
                    slug
                }
                pageCancellation {
                    id
                    name
                    slug
                }
                serviceHotline {
                    id
                    name
                    phone
                    eMail
                }
            }
        }
    }
`
